import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'w-spinner',
  templateUrl: './w-spinner.component.html',
  styleUrls: ['./w-spinner.component.scss']
})
export class WSpinnerComponent implements OnInit {

  @Input() size = 55;
  @Input() fullscreen = false;
  @Input() opaque = false;

  constructor() {}

  ngOnInit() {}
}
