import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { StorageService } from '@services/storage';
import { AuthResp } from '@services/api';

const TOKEN = 'ac_t';
const REFRESH_TOKEN = 'rfr_t';
const USER = 'u_i';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(private storageSrv: StorageService, private jwtSrv: JwtHelperService) {}

  isRegistered() {
    return !!this.getToken();
  }

  saveData({ accessToken, refreshToken }: AuthResp, rememberMe = true): void {
    const isStayLogged = rememberMe || this.storageSrv.getItem(TOKEN, localStorage);
    this.clearData();

    const storage = isStayLogged ? localStorage : sessionStorage;
    if (accessToken) this.storageSrv.setItem(TOKEN, accessToken, storage);
    if (refreshToken) this.storageSrv.setItem(REFRESH_TOKEN, refreshToken, storage);
  }

  isExpired(): boolean {
    return this.jwtSrv.isTokenExpired(this.getToken() || '');
  }

  getToken(): string | null {
    return this.storageSrv.getItem(TOKEN);
  }

  getRefreshToken(): string | null {
    return this.storageSrv.getItem(REFRESH_TOKEN);
  }

  getOwnID(): string {
    return this.storageSrv.getItem(USER) || '';
  }

  clearData() {
    [TOKEN, REFRESH_TOKEN, USER].forEach((src) => {
      this.storageSrv.removeItem(src);
      this.storageSrv.removeItem(src, sessionStorage);
    });
  }
}
