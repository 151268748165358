import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from "@services/auth";
import { SessionService } from "@services/session";

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
    constructor(private sessionSrv: SessionService, private router: Router, private authSrv: AuthService) {}


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.shouldExcludeRequestFromErrorHandling(req.url)) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse & { statusCode: HttpStatusCode }) => {
        if (error.status !== HttpStatusCode.Unauthorized) {
          // this.store.dispatch(genericFailure(error));
        }
        if (this.isRequestForbiddenForUser(error)) {
          this.router.navigate(['dashboard/cases'], { queryParams: { bypassUnsavedChanges: true } });
        }
        return throwError(() => error);
      }),
    );
  }

  shouldExcludeRequestFromErrorHandling(url: string): boolean {
    return url.includes('/auth') || url.includes('/user');
  }

  private isRequestForbiddenForUser(error: HttpErrorResponse): boolean {
    return error.status === HttpStatusCode.Forbidden && !!this.sessionSrv.getToken();
  }

}
