import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { SessionService } from '@services/session';
import { AuthService } from '@services/auth';
import { AppConfig } from '@config';

@Injectable()
export class ApiTokenInterceptor implements HttpInterceptor {
  constructor(private sessionSrv: SessionService, private authService: AuthService) {}

  private isExternalUrl(url: string) {
    return url.includes('http') && !url.includes(AppConfig.apiUrl);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isExternalUrl(req.url)) {
      return next.handle(req);
    }

    req = this.addAccessTokenHeader(req, this.sessionSrv.getToken() as string);

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.status !== 204) {
          return event.clone({ body: event.body });
        }
        return event;
      }),
      catchError((error) => this.handleApiError(req, error, next))
    );
  }

  private handleApiError(
    req: HttpRequest<any>,
    error: HttpErrorResponse,
    next: HttpHandler
  ): Observable<any> {
    if (error.status === HttpStatusCode.Unauthorized) {
      return this.handleUnauthorizedError(req, error, next);
    }
    return throwError(error.error);
  }

  private addAccessTokenHeader(req: HttpRequest<any>, accessToken: string): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  private handleUnauthorizedError(
    req: HttpRequest<any>,
    error: HttpErrorResponse,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.sessionSrv.getRefreshToken()) {
      return this.refreshToken(req, next, this.sessionSrv.getRefreshToken() as string);
    } else {
      this.authService.logout();
      throw error;
    }
  }

  refreshToken(req: HttpRequest<any>, next: HttpHandler, refreshToken: string): Observable<HttpEvent<any>> {
    return this.authService.refreshToken(refreshToken).pipe(
      switchMap(({ accessToken }) => {
        const reqWithNewAccessToken = this.addAccessTokenHeader(req, accessToken);
        return next.handle(reqWithNewAccessToken);
      }),
      catchError((error) => {
        this.authService.logout();
        return throwError(error);
      })
    );
  }
}
