import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WSpinnerComponent } from '@components/wrapped-components/w-spinner/w-spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [WSpinnerComponent],
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [WSpinnerComponent],
})
export class WSpinnerModule {}
