import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConfig } from '@config';
import { QueryStringService } from '@services/query-string';

interface KeyValue {
  [key: string]: any;
}

interface ApiOptions {
  params?: KeyValue;
  headers?: KeyValue;
}

interface IRequestOptions {
  headers: HttpHeaders;
  params: HttpParams;
  body: HttpParams;
  responseType: 'json'; // 'json' | 'blob' | 'text' and so on
}

export interface AuthResp {
  accessToken: string;
  refreshToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private querySrv: QueryStringService) {}

  get(path: string, options?: ApiOptions): Observable<any> {
    return this.http.get(`${AppConfig.apiUrl}${path}`, this.prepareOptions(options));
  }

  post(path: string, body: KeyValue, options?: ApiOptions): Observable<any> {
    return this.http.post(`${AppConfig.apiUrl}${path}`, body, this.prepareOptions(options));
  }

  put(path: string, body: KeyValue, options?: ApiOptions): Observable<any> {
    return this.http.put(`${AppConfig.apiUrl}${path}`, body, this.prepareOptions(options));
  }

  patch(path: string, body: KeyValue, options?: ApiOptions): Observable<any> {
    return this.http.patch(`${AppConfig.apiUrl}${path}`, body, this.prepareOptions(options));
  }

  delete(path: string, options?: ApiOptions): Observable<any> {
    return this.http.delete(`${AppConfig.apiUrl}${path}`, this.prepareOptions(options));
  }

  private prepareOptions(options: any): IRequestOptions {
    return {
      headers: new HttpHeaders(options?.headers),
      params: this.querySrv.buildParams(options?.params),
      body: (options && options.body) || {},
      responseType: (options && options.responseType) || 'json',
    };
  }
}
