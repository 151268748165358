import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WSpinnerModule } from '@components/wrapped-components/w-spinner/w-spinner.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { AppConfig } from '@config';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ApiTokenInterceptor } from "./interceptors/api-token.interceptor";
import { ErrorHandlingInterceptor } from "./interceptors/error-handling.interceptor";

export function jwtTokenGetter(): any {
  return null;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgProgressModule,
    NgProgressHttpModule,
    BrowserAnimationsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        skipWhenExpired: true,
        authScheme: '',
        allowedDomains: AppConfig.whitelistedDomains,
      },
    }),
    AngularSvgIconModule.forRoot(),
    WSpinnerModule,
  ],
  providers: [
      { provide: HTTP_INTERCEPTORS, useClass: ApiTokenInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlingInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
