import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { ApiService, AuthResp } from '@services/api';
import { SessionService } from '@services/session';
import { Router } from '@angular/router';
import { SignInRoute } from '@const';
import { Observable } from 'rxjs';

interface LoginReq {
  email: string;
  password: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private router: Router, private apiSrv: ApiService, private sessionSrv: SessionService) {}

  login(params: LoginReq, isRememberMe?: boolean) {
    return this.apiSrv
      .post('admin/login', params)
      .pipe(tap((data: AuthResp) => this.sessionSrv.saveData(data, isRememberMe)));
  }

  forgotPass(params: { email: string }) {
    return this.apiSrv.post('admin/forgot-password', params);
  }

  resetPass(params: { password: string; token: string }) {
    return this.apiSrv.post('admin/reset-password', params);
  }

  logout() {
    this.router.navigate([SignInRoute.ROOT_PATH]).then(() => this.sessionSrv.clearData());
  }

  refreshToken(refreshToken: string): Observable<{ accessToken: string; refreshToken: string }> {
    return this.apiSrv
      .post(`refresh-token`, {
        refreshToken: refreshToken,
      })
      .pipe(
        tap((data) => {
          this.sessionSrv.saveData(data);
        })
      );
  }
}
