import { Component, OnInit } from '@angular/core';
import { ProgressConfig } from 'ngx-progressbar/lib/ng-progress.interface';
import { IconsService } from '@services/icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'exhale-admin';

  isLoading = true;

  progressbarOptions: ProgressConfig = {
    min: 8,
    max: 100,
    ease: 'linear',
    speed: 200,
    trickleSpeed: 300,
    meteor: true,
    spinner: false,
    spinnerPosition: 'right',
    direction: 'ltr+',
    color: '#f8d9c5',
    thick: true,
    fixed: true,
    trickleFunc: (n) => n,
    debounceTime: 0,
  };

  constructor(private iconsSrv: IconsService) {}

  ngOnInit() {
    this.iconsSrv.registerIcons();
  }

  onActivate() {
    this.isLoading = false;
  }
}
